<template>
  <div
    class="w-full flex flex-wrap justify-center"
  >
    <loader v-if="showLoader" />
    <div class="container">
      <div class="text-2xl sm:text-3xl md:text-4xl font-extrabold flex">
        <h2 class="t-title-2">
          {{ productData.name }}
        </h2>
      </div>
      <div class="text-secondary mb-5 rounded-md px-5 y-2">
        <p class="t-alert success">
          A continuación, captura la información de pago, nosotros no guardarémos esa información.
        </p>
      </div>
      <!-- <div class="w-full px-5">
        <div class="t-form-section">
          <div class="t-section-title py-0">Datos de pago</div>
          <div class="t-section-content py-5">
            <div class="t-input-group">
              
            </div>
          </div>
        </div>
      </div> -->





      <div v-if="!isMobileView" class="flex flex-wrap w-full flex-col">
        <form
          @submit.prevent="makePayment()"
          class="flex flex-col flex-wrap items-center"
        >
          <h3 class="w-full text-center my-5 text-primary text-2xl">
            Rellena los datos de pago:
          </h3>
          
          <div class="t-checkout-card relative bg-purple-50 rounded-xl shadow-3xl p-5 text-center mb-10">
            <img src="../assets/img/stripe/chip.png" class="chip mt-2" width="60" height="50"/>
            
            <h3 class="absolute top-5 right-10 text-gray-500 font-sans uppercase">Bancos</h3>

            <div class="logo"></div>
            <div class="data-form justify-around">
              <div class="card-input-group">
                <span>No. de Tarjeta</span>
                <div id="card-number" class="transform" style="max-width: 150px !important; transform: scale(1.8) translateX(50px) !important"></div>
              </div>
              <div class="flex justify-around">
                <div class="card-input-group w-full flex justify-around">
                  <div>
                    <span>Valido hasta</span>
                    <div id="card-expiry" class="transform" style="max-width: 100px !important; transform: scale(1.2) translateX(30px) !important"></div>
                  </div>
                </div>
                <div class="card-input-group w-full flex justify-around">
                  <div>
                    <span>CVV</span>
                    <div id="card-cvc" class="transform" style="max-width: 100px !important; transform: scale(1.2) translateX(30px) !important"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-checkout"></div>
          <div class="card-error card invalid w-full"></div>
          <div class="card-error expirity invalid w-full"></div>
          
          <div class="my-1 text-2xl"><span class="text-primary-dark font-extrabold">{{ productData.name }}</span></div>
          <div class="my-1 text-3xl"> <span class="text-secondary font-extrabold">${{ numberWithCommas(Number(productData.payment_amount)) }} MXN</span></div>
          <button
            type="submit"
            class="
              bg-special-2
              px-3
              py-1
              m-5
              rounded-md
              border-2 border-secondary
            "
          >
            Realizar pago
          </button>
        </form>
      </div>






      <div v-if="isMobileView" class="flex flex-wrap w-full flex-col">
        <form
          @submit.prevent="makePayment()"
          class="flex flex-col flex-wrap items-center"
        >
          <h3 class="w-full text-center my-5 text-primary text-2xl" style="text-align: left;">
            Rellena los datos de pago:
          </h3>
          
          <div class="t-checkout-card relative bg-purple-50 rounded-xl shadow-3xl p-5 text-center mb-10" style="transform:scale(0.8); position:absolute; margin: 60px 0 100px 0;">
            <img src="../assets/img/stripe/chip.png" class="chip mt-2" width="60" height="50"/>
            <h3 class="absolute top-5 right-10 text-gray-500 font-sans uppercase">Banco</h3>
            <div class="logo"></div>
            <div class="data-form justify-around">
              <div class="card-input-group">
                <span>No. de Tarjeta</span>
                <div id="card-number" class="transform" style="max-width: 150px !important; transform: scale(1.8) translateX(50px) !important"></div>
              </div>
              <div class="flex justify-around">
                <div class="card-input-group w-full flex justify-around">
                  <div>
                    <span>Valido hasta</span>
                    <div id="card-expiry" class="transform" style="max-width: 100px !important; transform: scale(1.2) translateX(30px) !important"></div>
                  </div>
                </div>
                <div class="card-input-group w-full flex justify-around">
                  <div>
                    <span>CVV</span>
                    <div id="card-cvc" class="transform" style="max-width: 100px !important; transform: scale(1.2) translateX(30px) !important"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          
          <div class="card-checkout"></div>
          <div class="card-error card invalid w-full"></div>
          <div class="card-error expirity invalid w-full"></div>
          
          <div class="my-1 text-2xl"><span class="text-primary-dark font-extrabold">{{ productData.name }}</span></div>
          <div class="my-1 text-3xl"> <span class="text-secondary font-extrabold">${{ numberWithCommas(Number(productData.payment_amount)) }} MXN</span></div>
          <button
            type="submit"
            class="
              bg-special-2
              px-3
              py-1
              m-5
              rounded-md
              border-2 border-secondary
            "
          >
            Realizar pago
          </button>
        </form>
      </div>










      
    </div>
  </div>
</template>

<script>
import { stripePk } from "../constants/config";
import { APIServices } from "../constants/config";
import Loader from "../components/Loader.vue"

export default {
  name: "PruebaCargos",
  data: function () {
    return {
      showLoader: false,
      stripe: null,
      stripePk: stripePk,
      paymentError: "",
      elements: null,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      productItemLocal: null,
    };
  },
  props: {
    stripeAccount: {
      type: String,
      required: true,
    },
    clientSecret: {
      type: String,
      required: true,
    },
    userData: {
      required: true,
    },
    productData: {
      required: true,
    },
  },
  components: {
      "loader": Loader
  }, 
  mounted() {
    let self = this;
    self.$store.commit("updateBgApp", "");
    var style = {
      base: {
        color: "#32325d",
        '::placeholder': {
          color: '#ccd3da',
        },
      },
    };
    self.stripe = window.Stripe(self.stripePk, {
      stripeAccount: self.stripeAccount,
    });

    self.elements = self.stripe.elements();
    this.cardNumber = self.elements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = self.elements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = self.elements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
    
    this.cardNumber.on("change", this.updateCardErrors);
    this.cardExpiry.on("change", this.updateCardErrors);

    // registerElements([cardNumber, cardExpiry, cardCvc], 'example3');
    // this.registerElements([this.cardNumber, this.cardExpiry, this.cardCvc], 'card-checkout');
    // console.log(self.stripe);
    // this.cardCvc.on("change", this.updateCardErrors);

    // var card = self.elements.create("card", { style: style });
    // console.log("cardObject1", card);
    // card.mount("#card-element");
    // card.on("change", function (event) {
    //   var displayError = document.getElementById("card-error");
    //   if (event.error) {
    //     displayError.textContent = event.error.message;
    //   } else {
    //     displayError.textContent = "";
    //   }
    // });


    setTimeout(function () {
      self.productItemLocal = {};
      self.productItemLocal = Object.assign(
        {},
        self.productItemLocal,
        self.productItem
      );
    }, 300);

  },
  computed: {
    isMobileView() {
      return this.$store.state.GeneralConfigStore.isMobileView;
    }
  },
  methods: {
    updateCardErrors(event) {
      // console.log(event);
      var displayError = null;
      if (event.elementType == "cardNumber") {
        displayError = document.querySelector(".card-error.card");
      } else {
        displayError = document.querySelector(".card-error.expirity");
      }
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    },
    numberWithCommas(x) {
      return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    makePayment() {
      let self = this;
      let card = self.cardNumber;
      self.showLoader = true;

      self.stripe
        .confirmCardPayment(self.clientSecret, {
          payment_method: {
            card: card,
            billing_details: {
              name: self.userData.name,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            self.paymentError = result.error;
            self.showLoader = false;
            // Show error to your customer (e.g., insufficient funds)

            self.$swal({
              icon: "error",
              title: "No pudimos realizar el cargo",
              text: self.paymentError.message,
              showConfirmButton:false,
              timer: 10000,
            });
            
          } else {
            self.paymentError = null;
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded") {

              self.$swal({
                icon: "success",
                title: "Cargo exitoso",
                text: "El cargo se ha efectuado correctamente",
                showConfirmButton:false,
                timer: 10000,
              });
              
              self.processPayment();
            }
          }
        });
    },
    async processPayment() {
      let self = this;
      let payload = {};
      // console.log("self.productData", self.productData);
      let id = self.productData.id;
      let url = APIServices.product_payment + id + "/";
      
      await self.$http.patch(url, payload).then(() => {
        // console.log("Result", result);
      }).catch(() => {
        // console.log(err);
      }).finally(() => {
        // console.log("Cerrando");
        // window.close()
        self.$emit("payed");
        self.showLoader = false;
      });
    },
    registerElements(elements, exampleName) {
      var formClass = '.' + exampleName;
      var example = document.querySelector(formClass);

      var form = example.querySelector('form');
      var resetButton = example.querySelector('a.reset');
      var error = form.querySelector('.error');
      var errorMessage = error.querySelector('.message');

      function enableInputs() {
        Array.prototype.forEach.call(
          form.querySelectorAll(
            "input[type='text'], input[type='email'], input[type='tel']"
          ),
          function(input) {
            input.removeAttribute('disabled');
          }
        );
      }

      function disableInputs() {
        Array.prototype.forEach.call(
          form.querySelectorAll(
            "input[type='text'], input[type='email'], input[type='tel']"
          ),
          function(input) {
            input.setAttribute('disabled', 'true');
          }
        );
      }

      // Listen for errors from each Element, and show error messages in the UI.
      elements.forEach(function(element) {
        element.on('change', function(event) {
          if (event.error) {
            error.classList.add('visible');
            errorMessage.innerText = event.error.message;
          } else {
            error.classList.remove('visible');
          }
        });
      });

      // Listen on the form's 'submit' handler...
      form.addEventListener('submit', function(e) {
        e.preventDefault();

        // Show a loading screen...
        example.classList.add('submitting');

        // Disable all inputs.
        disableInputs();

        // Gather additional customer data we may have collected in our form.
        var name = form.querySelector('#' + exampleName + '-name');
        var address1 = form.querySelector('#' + exampleName + '-address');
        var city = form.querySelector('#' + exampleName + '-city');
        var state = form.querySelector('#' + exampleName + '-state');
        var zip = form.querySelector('#' + exampleName + '-zip');
        var additionalData = {
          name: name ? name.value : undefined,
          address_line1: address1 ? address1.value : undefined,
          address_city: city ? city.value : undefined,
          address_state: state ? state.value : undefined,
          address_zip: zip ? zip.value : undefined,
        };

        // Use Stripe.js to create a token. We only need to pass in one Element
        // from the Element group in order to create a token. We can also pass
        // in the additional customer data we collected in our form.
        
        this.stripe.createToken(elements[0], additionalData).then(function(result) {
          // Stop loading!
          example.classList.remove('submitting');

          if (result.token) {
            // If we received a token, show the token ID.
            example.querySelector('.token').innerText = result.token.id;
            example.classList.add('submitted');
          } else {
            // Otherwise, un-disable inputs.
            enableInputs();
          }
        });
      });

      resetButton.addEventListener('click', function(e) {
        e.preventDefault();
        // Resetting the form (instead of setting the value to `''` for each input)
        // helps us clear webkit autofill styles.
        form.reset();

        // Clear each Element.
        elements.forEach(function(element) {
          element.clear();
        });

        // Reset error state as well.
        error.classList.remove('visible');

        // Resetting the form does not un-disable inputs, so we need to do it separately:
        enableInputs();
        example.classList.remove('submitted');
      });
    }
  },
};
</script>
<style >
.t-checkout-card {
  overflow: hidden;
  width: calc(116px * 3.5);
  height: calc(70px * 3.5);

  &::before {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    left: -10%;
    top: -30%;
    border-radius: 50%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), transparent);
  }

  .chip {
    position: absolute;
  }
  .logo {
    position: absolute;
    top: 150px;
    right: 40px;
    width: 60px;
    height: 60px;
    background: #ea222c;
    border-radius: 50%;
    box-shadow: -40px 0 0 rgba(0, 0, 0, 0.5);
  }
  .data-form {
    color: var(--primary);
    padding-top: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .card-input-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      text-align: start;
      justify-content: space-between;
    }
  }
}
</style>
