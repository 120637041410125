<template>
  <div
    class="w-full flex flex-wrap justify-center"
  >
    <loader v-if="showLoader" />
    <div class="container">
      <div class="text-2xl sm:text-3xl md:text-4xl font-extrabold flex">
        <h2 class="t-title-2">
          {{ productData.name }}
        </h2>
      </div>
      <div class="text-secondary mb-5 rounded-md px-5 y-2">
        <p class="t-alert success">
          A continuación, captura la información de pago, nosotros no guardarémos esa información.
        </p>
      </div>


      <div v-if="!isMobileView" class="flex flex-wrap w-full flex-col">
        

        <form
          @submit.prevent="makePayment()"
          class="flex flex-col flex-wrap items-center"
        >
          <h3 class="w-full text-center my-5 text-primary text-2xl">
            Rellena los datos de pago:
          </h3>

          <div class="soap_payment_card t-checkout-card relative rounded-xl shadow-3xl p-5 text-center mb-10">
            
            <div id="header_card">
              <img src="../assets/img/stripe/chip.png" class="chip mt-2" width="60" height="50"/>
              <h3 class="absolute top-5 right-10 text-gray-500 font-sans uppercase">Bancos</h3>
            </div>

            <div id="body_card" class="data-form justify-around">
              
              <div id="card_number_container" class="card-input-group">
                
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                  Boolean(
                    ($v.card_number && !$v.card_number.$invalid) ||
                      !$v.card_number
                  )"
                  :text="'No. de Tarjeta'"
                  :required="true"
                />
                
                <div id="card_number" class="transform">
                
                  <input
                    v-model="card_number"
                    min="1"
                    maxlength = "16"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                    type="number"
                    name="card_number"
                    placeholder="1234 1234 1234 1234"
                    class="t-form"
                    :class="[
                      $v.card_number.$invalid && showErrors
                        ? 'invalid'
                        : ''
                    ]"
                  />

                </div>
                

              </div>
              

              <div id="card_security_container" class="flex justify-around">
                
                <div class="card-input-group w-full flex justify-around">
                  
                  <div>

                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                      Boolean(
                        ($v.card_month_expiricy && !$v.card_month_expiricy.$invalid) && ($v.card_year_expiricy && !$v.card_year_expiricy.$invalid)
                        || !$v.card_month_expiricy || !$v.card_year_expiricy
                      )"
                      
                      :text="'Válido hasta'"
                      :required="true"
                    />

                    <div id="card-expiry" class="transform">
                    
                      <input
                        v-model="card_month_expiricy"
                        min="1" max="12"
                        maxlength = "2"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                        type="number"
                        name="card_number"
                        placeholder="MM"
                        class="t-form"
                        :class="[
                          $v.card_month_expiricy.$invalid && showErrors
                            ? 'invalid'
                            : ''
                        ]"
                      />

                      <input
                        v-model="card_year_expiricy"
                        min="23" max="100"
                        maxlength = "2"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                        type="number"
                        name="card_number"
                        placeholder="AA"
                        class="t-form"
                        :class="[
                          $v.card_year_expiricy.$invalid && showErrors
                            ? 'invalid'
                            : ''
                        ]"
                      />

                    </div>

                  </div>
                </div>
                
                <div class="card-input-group w-full flex justify-around">
                  
                  <div id="card_cvc_container">

                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                      Boolean(
                        ($v.card_cvv && !$v.card_cvv.$invalid) ||
                          !$v.card_cvv
                      )"
                      :text="'CVV'"
                      :required="true"
                    />
                    
                    <div id="card_cvc" class="transform">
                      <input
                        v-model="card_cvv"
                        maxlength = "3"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                        type="number"
                        name="card_number"
                        placeholder="CVV"
                        class="t-form"
                        :class="[
                          $v.card_cvv.$invalid && showErrors
                            ? 'invalid'
                            : ''
                        ]"
                      />
                    </div>

                  </div>
                </div>

              </div>

            </div>



            <div id="footer_card">
              <!-- Visa/Mastercard -->
              <img v-if="[2].includes(item_payment.category.id)" id="visa_mastercard" src="@/assets/img/banks/visa_mastercard.jpeg" class="chip"/>

              <!-- Amex -->
              <img v-if="[].includes(item_payment.category.id)" id="amex" src="@/assets/img/banks/amex.png" class="chip"/>
            </div>
            
          </div>


          
          <div class="card-checkout"></div>
          <div class="card-error card invalid w-full"></div>
          <div class="card-error expirity invalid w-full"></div>
          
          <div class="my-1 text-2xl"><span class="text-primary-dark font-extrabold">{{ productData.name }}</span></div>
          <div class="my-1 text-3xl"> <span class="text-secondary font-extrabold">${{ numberWithCommas(Number(productData.payment_amount)) }} MXN</span></div>
          <button
            type="submit"
            class="
              bg-special-2
              px-3
              py-1
              m-5
              rounded-md
              border-2 border-secondary
            "
          >
            Realizar pago
          </button>
        </form>


      </div>






      <div v-if="isMobileView" class="flex flex-wrap w-full flex-col">
        <form
          @submit.prevent="makePayment()"
          class="flex flex-col flex-wrap items-center"
        >
          <h3 class="w-full text-center my-5 text-primary text-2xl" style="text-align: left;">
            Rellena los datos de pago:
          </h3>
          
          <div class="soap_payment_card t-checkout-card relative bg-purple-50 rounded-xl shadow-3xl p-5 text-center mb-10" style="transform:scale(0.8); position:absolute; margin: 60px 0 100px 0;">
            
            <div id="header_card">
              <img src="../assets/img/stripe/chip.png" class="chip mt-2" width="60" height="50"/>
              <h3 class="absolute top-5 right-10 text-gray-500 font-sans uppercase">Bancos</h3>
            </div>            


            <div id="body_card" class="data-form justify-around">

              <div id="card_number_container" class="card-input-group">
                
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                  Boolean(
                    ($v.card_number && !$v.card_number.$invalid) ||
                      !$v.card_number
                  )"
                  :text="'No. de Tarjeta'"
                  :required="true"
                />
                
                <div id="card_number" class="transform">
                
                  <input
                    v-model="card_number"
                    min="1"
                    maxlength = "16"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                    type="number"
                    name="card_number"
                    placeholder="1234 1234 1234 1234"
                    class="t-form"
                    :class="[
                      $v.card_number.$invalid && showErrors
                        ? 'invalid'
                        : ''
                    ]"
                  />

                </div>
                

              </div>
              

              <div id="card_security_container" class="flex justify-around">
                
                <div class="card-input-group w-full flex justify-around">
                  
                  <div>

                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                      Boolean(
                        ($v.card_month_expiricy && !$v.card_month_expiricy.$invalid) && ($v.card_year_expiricy && !$v.card_year_expiricy.$invalid)
                        || !$v.card_month_expiricy || !$v.card_year_expiricy
                      )"
                      
                      :text="'Válido hasta'"
                      :required="true"
                    />

                    <div id="card-expiry" class="transform">
                    
                      <input
                        v-model="card_month_expiricy"
                        min="1" max="12"
                        maxlength = "2"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                        type="number"
                        name="card_number"
                        placeholder="MM"
                        class="t-form"
                        :class="[
                          $v.card_month_expiricy.$invalid && showErrors
                            ? 'invalid'
                            : ''
                        ]"
                      />

                      <input
                        v-model="card_year_expiricy"
                        min="23" max="100"
                        maxlength = "2"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                        type="number"
                        name="card_number"
                        placeholder="AA"
                        class="t-form"
                        :class="[
                          $v.card_year_expiricy.$invalid && showErrors
                            ? 'invalid'
                            : ''
                        ]"
                      />

                    </div>

                  </div>
                </div>
                
                <div class="card-input-group w-full flex justify-around">
                  
                  <div id="card_cvc_container">

                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                      Boolean(
                        ($v.card_cvv && !$v.card_cvv.$invalid) ||
                          !$v.card_cvv
                      )"
                      :text="'CVV'"
                      :required="true"
                    />
                    
                    <div id="card_cvc" class="transform">
                      <input
                        v-model="card_cvv"
                        maxlength = "3"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"                        
                        type="number"
                        name="card_number"
                        placeholder="CVV"
                        class="t-form"
                        :class="[
                          $v.card_cvv.$invalid && showErrors
                            ? 'invalid'
                            : ''
                        ]"
                      />
                    </div>

                  </div>
                </div>

              </div>

            </div>


            <div id="footer_card">
              <!-- Visa/Mastercard -->
              <img v-if="[2].includes(item_payment.category.id)" id="visa_mastercard" src="@/assets/img/banks/visa_mastercard.jpeg" class="chip"/>

              <!-- Amex -->
              <img v-if="[].includes(item_payment.category.id)" id="amex" src="@/assets/img/banks/amex.png" class="chip"/>
            </div>

          </div>

          
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          
          <div class="card-checkout"></div>
          <div class="card-error card invalid w-full"></div>
          <div class="card-error expirity invalid w-full"></div>
          
          <div class="my-1 text-2xl"><span class="text-primary-dark font-extrabold">{{ productData.name }}</span></div>
          <div class="my-1 text-3xl"> <span class="text-secondary font-extrabold">${{ numberWithCommas(Number(productData.payment_amount)) }} MXN</span></div>
          <button
            type="submit"
            class="
              bg-special-2
              px-3
              py-1
              m-5
              rounded-md
              border-2 border-secondary
            "
          >
            Realizar pago
          </button>
        </form>
      </div>










      
    </div>
  </div>
</template>

<script>
import { APIServices } from "../constants/config"
import Loader from "../components/Loader.vue"
import { mapGetters } from "vuex"
import {
  required,
  // helpers,
  // numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators"

export default {
  name: "PruebaCargos",
  data: function () {
    return {
      showLoader: false,
      paymentError: "",
      elements: null,
      token: null,
      productItemLocal: null,
      showErrors:false,
      card_number: "",
      card_month_expiricy: "",
      card_year_expiricy: "",
      card_cvv: "",
    }
  },

  validations() {

    let validations = {

      card_number: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      card_month_expiricy: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2),
      },
      card_year_expiricy: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2),
      },
      card_cvv: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(3),
      },

    }

    // if (this.formTypeShowed.id == 1 && !this.loggedIn) {
    //   /* Account create */
    //   validations.formData["email"] = {
    //     required,
    //     email,
    //   };
    //   validations.formData["email_c"] = {
    //     required,
    //     sameAsEmail: sameAs("email"),
    //   };
    //   validations.formData["password"] = {
    //     required,
    //     hasSymbol,
    //     minLength: minLength(5),
    //   };
    //   validations.formData["password_c"] = {
    //     required,
    //     sameAsPassword: sameAs("password"),
    //   };
    // }

    return validations;
  },
  props: {
    userData: {
      required: true,
    },
    productData: {
      required: true,
    },
    item_payment: {
      required: true,
    },
  },
  components: {
      "loader": Loader
  }, 
  mounted() {

    let self = this;
    self.$store.commit("updateBgApp", "");

    setTimeout(function () {
      self.productItemLocal = {};
      self.productItemLocal = Object.assign(
        {},
        self.productItemLocal,
        self.productItem
      )
    }, 300)

  },
  computed: {
    ...mapGetters(["shoppingCar"]),
    isMobileView() {
      return this.$store.state.GeneralConfigStore.isMobileView;
    }
  },
  methods: {
    updateCardErrors(event) {
      // console.log(event);
      var displayError = null;
      if (event.elementType == "card_number") {
        displayError = document.querySelector(".card-error.card");
      } else {
        displayError = document.querySelector(".card-error.expirity");
      }
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    },
    numberWithCommas(x) {
      return parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    makePayment() {

      let self = this;      
      // console.log(self.card_number)
      // console.log(self.card_month_expiricy)
      // console.log(self.card_year_expiricy)
      // console.log(self.card_cvv)
      // console.log(self.item_payment)
      // console.log(self.showErrors)

      if (!self.$v.$invalid) {
        self.showErrors = false
        var data = {
          name: self.item_payment.extra_data.baseData.full_name,
          card_number:self.card_number,
          card_month_expiricy:self.card_month_expiricy,
          card_year_expiricy:self.card_year_expiricy,
          card_cvv:self.card_cvv,
          policy:0
        }
        
        self.showLoader = true
        self.shoppingCar.generatePaymentProcessIntent(self.item_payment , data).then(response => {

          var data = response.data
          // console.log(data)

          if(data.response){
            // The payment has been processed!
            self.$swal({
              icon: "success",
              title: data.message.title,
              text: data.message.text,
              showConfirmButton:false,
              timer: 10000,
            });

            self.processPayment();

          } else {
            // The payment has not been processed
            self.showLoader = false
            self.$swal({
              icon: "error",
              title: data.message.title,
              text: data.message.text,
              showConfirmButton:false,
              timer: 10000,
            })

            
          }
        })
        .catch(error => {
          self.showLoader = false
          console.log(error)
          if (error.response) {
            console.log(error.response)
          }
        })
        .finally(() => {
          // self.showLoader = false
        })

      } else {
        self.showErrors = true;
      }
          
    },
    async processPayment() {

      let self = this;
      let payload = {};
      // console.log("self.productData", self.productData);
      let id = self.productData.id;
      let url = APIServices.product_payment + id + "/";
      
      self.showLoader = true
      await self.$http.patch(url, payload).then(() => {
        // console.log("Result", result);
      }).catch(() => {
        // console.log(err);
      }).finally(() => {
        // console.log("Cerrando");
        // window.close()
        self.$emit("payed");
        self.showLoader = false;
      })

    },
  },
};
</script>


<style >
.t-checkout-card {
  overflow: hidden;
  width: calc(116px * 3.5);
  height: calc(70px * 3.5);

  &::before {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    left: -10%;
    top: -30%;
    border-radius: 50%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), transparent);
  }

  .chip {
    position: absolute;
  }
  .logo {
    position: absolute;
    top: 150px;
    right: 40px;
    width: 60px;
    height: 60px;
    background: #ea222c;
    border-radius: 50%;
    box-shadow: -40px 0 0 rgba(0, 0, 0, 0.5);
  }
  .data-form {
    color: var(--primary);
    padding-top: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>
